.legal-layout{
    .header-container{
        display: flex;
        background: var(--color-secondary);
        h2{
            font-size: 64px;
            line-height: 72px;
            letter-spacing: -0.88px;
            margin: 112px 10px;
            color: #fff;
        }
    }
    .page-content{
        display: flex;
        .sidebar{
            min-width: 305px;
            margin-right: 10px;
            
            border-right: 0.5px solid #b0b0b0;
            .sidebar-container{
                width: 276px;
                padding: 48px 5px;
                position: sticky;
                align-self: flex-start;
                top: 0;
                ul{
                    li{
                        line-height: 200%;
                    }
                }
            }
            .bold{
                font-weight: 700;
            }
            li.active > a{
                    color: var(--color-primary);
                    text-decoration: underline;
            }
            li.parent-link > a{
                font-size: 18px;
            }
        }
        .page-body{
            padding-left: 40px;
            padding-top: 48px;
            padding-right: 5px;
            padding-bottom: 70px;
            p.last-updated{
                color: #808080;
                font-weight: 700;
            }
            .bold{
                font-weight: 700;
            }
            ul{
                li{
                    display:flex;
                    margin: 14px 0px;
                    span.circle-container{
                        min-width: 25px;
                        padding-top: 5px;
                        span.circle{
                            display: block;
                            border-radius: 100%;    
                            width: 12px;
                            height: 12px;
                            background:  var(--color-primary);
                        }
                    }
                }
            }
        }
    }
}