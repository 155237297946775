.signup-section{
    
    font-family: Open Sans;
    
    .signup-container{
        display: flex;
        align-items: center;
        justify-content: space-around;
        align-content: center;
    }

    .signup-block{
        width: 100%;
        height:100vh;
        text-align: center;
    }

    .signup-business{
        background: var(--url-login-bg);
        background-size: cover;
    }

    .signup-creator{
        background: var(--url-creator-signup-bg);
        background-size: cover;
    }

    .signup-form{
        padding:50px;
        margin:auto;
        .form-group{
            text-align: left;
        }
    }

}
.login-section{
    width: 512px;
    .login-header{
        margin-bottom: 60px;
        h2{
            font-size: 48px;
            letter-spacing: -0.48px;
            color: #2f2f2f;
        }
        p{
            font-size: 16px;
            letter-spacing: 0.28px;
            text-align: left;
            color: #2f2f2f;
        }
    }
    .btn-login{
        background: var(--color-highlight);
    }
}
@media (min-width: 0) and (max-width: 767px) {
    .signup-section{
        .signup-head{
            display: none;
        }
    }
    .login-section{
        max-width: 512px;
        width: auto;   
        h2{
            font-size: 2rem;
        }
        p{
            font-size: 1rem;
        }
    }
}