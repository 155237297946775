// $primary: #292929;
// $secondary: #292929;
// $footer: #292929;
$border: #cecece;
$shadow: #c5c5c5;
$lightText: #6e6e6e;
$lightGray: #f8f9fa;
$text: #292929;
$inputBg: #f1f1f1;
$danger: #f44336;
$success: #60b838;
$white: #ffffff;
$dark: #000000;
$blueBtn: #5259B6;
$yelowBtn: #F9BB00;
$redBtn: #FF6565;
// $aquaBtn: #DC1871;
$aquaGreen: #53BC9C;
$lightpink: #FCF3F3;
// $darkpink: #e960ae;

// $color-primary: #DC1871;
// $color-secondary: var(--color-secondary);

