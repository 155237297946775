.signup-section{
    
    font-family: Open Sans;
    
    .signup-container{
        display: flex;
        align-items: center;
        justify-content: space-around;
        align-content: center;
    }

    .signup-block{
        width: 100%;
        height:100vh;
        text-align: center;
    }

    .signup-head{
        background: #f7f7f7 url(https://ik.imagekit.io/99x/startup/auth/Component_1_jpfnEoJsZ.png);
        background-size: cover;
    }

    .signup-form{
        padding:50px;
        margin:auto;
        .form-group{
            text-align: left;
        }
    }

    a.muted-link{
        font-size: inherit;
    }
    .already-block{
        span{
            font-size: 14px;
        }
        font-size: 14px;
        a{
            font-size: 14px;
            color: var(--color-highlight);
        }
        a:hover{
            text-decoration: underline;
        }
    }

}

@media (min-width: 0) and (max-width: 767px) {
    .signup-section{
        .signup-head{
            display: none;
        }
    }
}