:root{
    --color-primary:#DC1871;
    --color-primary-light: #e960ae;
    --color-secondary: #424BA6;
    --color-highlight: #42AC97;
    --color-3: #252B62;
    --color-bg-subscribe-button: #42AC97;
    --url-login-bg: #fff url('https://ik.imagekit.io/99x/startup/auth/Component_1_jpfnEoJsZ.png');
    --url-creator-signup-bg: #f7f7f7 url(https://ik.imagekit.io/99x/startup/CreatorSignup_CtyDe341F.png);
    --subscription-home-min-height: 500px;
    --color-bg:#fff;
    --show-nav:block;
}