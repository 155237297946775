@mixin breakpoint($point, $value: 0) {
    @if $point==mobile-portrait {
        @media (min-width: 0px) and (max-width: 479px) {
            @content;
        }
    }
    @else if $point==mobile {
        @media (min-width: 0) and (max-width: 767px) {
            @content;
        }
    }
    @else if $point==upto-tablet {
        @media (min-width: 0) and (max-width: 991px) {
            @content;
        }
    }
    @else if $point==tablet {
        @media (min-width: 768px) and (max-width: 991px) {
            @content;
        }
    }
    @else if $point==tablet-above {
        @media (min-width: 768px) {
            @content;
        }
    }
    @else if $point==desktop {
        @media (min-width: 992px) {
            @content;
        }
    }
    @else if $point==lg-desktop {
        @media (min-width: 1200px) {
            @content;
        }
    }
    @else {
        @media ($point: $value) {
            @content;
        }
    }
}

@mixin between-breakpoints($min, $max) {
    @media (min-width: $min) and (max-width: $max) {
        @content;
    }
}
