@import '../../assets/scss/colours.scss';
@import '../../assets/scss/breakpoints.scss';

.profile_menu{
    .profile_submenu{
        display: none;
        position: absolute;
    }
}
.profile_menu:hover{
    .profile_submenu{
        display: block;
    }    
}

header{
    background-color: var(--color-bg);
}
.custom-nav ul li{
    padding: 7px 14px;
}
.custom-nav ul li:last-child{
    padding: 7px 0px 7px 14px;
}
.custom-nav ul li a{
    font-size: 12px;
    color: $text;
    display: inline-block;
    transition: 0.3s;
}
.custom-nav ul li a:hover{
    color: #53bc9c;
}
.custom-main-nav-wrapper{
    align-items: center;
    justify-content: space-between;
    & .custom-main-nav{
        padding: 0px 20px;
        flex: 1 0 auto;
        transition: all 0.3s;
        & ul{
            display: flex;
            flex-direction: row;
            & li{
                padding: 7px 14px;
                & a{
                    color: $text;
                    display: inline-block;
                    transition: 0.3s;
                    font-size: 14px;
                    font-weight: 700;
                    font-family: 'PT Sans';
                    &:hover{
                        color: var(--color-primary);
                    }
                }
                @include breakpoint(desktop){
                    padding: 5px 9px;
                }
                @include breakpoint(lg-desktop){
                    padding: 7px 14px;
                }
            }
        }
        @include breakpoint(upto-tablet){
            position: absolute;
            top: 100%;
            left: 0px;
            right: 0px;
            background-color: $white;
            box-shadow: 0px 6px 9px 0px $border;
            border-top: 1px solid $border;
            z-index: 20;
            height: 0px;
            overflow: hidden;
            & ul{
                display: flex;
                flex-direction: column;
                & li{
                    width: 100%;
                }
            }
            &.active{
                height: 190px;
            }
        }
    }
    @include breakpoint(upto-tablet){
        justify-content: space-between;
    }
}
.logo{
    width: 111px;
    @include breakpoint(upto-tablet){
        width: 90px;
    }
}
.nav-toggler{
    border: 1px solid $text;
    background-color: $white;
    padding: 3px 10px;
    margin-right:10px;
    display: none;
    @include breakpoint(upto-tablet){
        display: inline-block;
    }
}